@import './global.scss';

    .custom-modal-css {
        background: transparent !important;
        padding: 10px !important;
    }

    .custom-modal-css {
        &::part(content) {
            border-radius: 10px; // Here the border radius
        }
        
    }


