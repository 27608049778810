/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import './app/directives/mat-table-responsive.directive';

//Swiper
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

ion-content{

    --ion-background-color:white;
}
$xs: 0px;
$sm: 600px;
$md: 768px;
$lg: 992px;

$lightPurple: #f8eff9;

$bl-purple: #A356AB;

$bl-purple-dark:rgb(109,52,114);
$bl-generic-text: white;

ion-content{
    --keyboard-offset: 0 !important;
 }

input {
    background: white;
    color: black;
}

.toolbar-content {
    text-align: center;
}

.page-header {
    font-size: 4vh;
    text-align: center;
    margin: 0;
    margin-bottom: 2vh;
}

.card {
    border: 1px solid #ECECEC;
    border-radius: 5px;
    margin-top: 10%;
    box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 22%);
    padding: 10px 10px 10px 10px;
    border-bottom: 2px solid gray;
}


.page-footer-container {
    position: absolute;
    bottom: 2vh;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.page-footer-button {
    width: 100%;
    height: 6vh;
}

.page-footer-container-row {
    display: flex;
    width: 95%;
}


.header {
    font-size: 4vh;
    margin: 0;
    margin-bottom: 1vh;
}

//Stops android keyboard from pushing content
.ionic-content {
    --offset-bottom: 0 !important;
 }

nav {
    width: 100%;
}

.transparent-white-input {
    color: white !important;
    border: 1px solid white;
    border-radius: 10px;
    border-radius: 10px;
    background: transparent;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px
    
}

.transparent-white-input::placeholder{
    color: white;
}

.white-input {
    color: black !important;
    background: white;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 3px;
  }

.white-button {
    background-color: white;
    color: gray;
    border: 1px solid gray;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 19px;
    border-radius: 10px;
    width: 100%;
}


::ng-deep .scroll-y {
    --overflow-y: hidden !important;
}

::ng-deep main {
    overflow-y: hidden !important;
}

::ng-deep .inner-scroll, .scroll-y  {
    overflow: hidden !important;
    overflow-y: hidden !important;
}

ion-content {--overflow: hidden}


.page-container {
    overflow-y: scroll;
    top: 0vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 5vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

nav {
    z-index: 100;
}
