@import './global.scss';

.date-time-picker {
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 1px 6px 1px rgb(0 0 0 / 27%);
    border: 1px solid lightgray;
}

//INPUT TEXT

.bl-input-form-field {
    //So floating label doesn't touch anything
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 1.5vh;
    margin-top: 1.5vh;
}

.bl-input {
    width: 100%;
}



//Makes the mat label float above when there's text filled in
mat-form-field > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix > .mat-form-field-label-wrapper > .mat-form-field-label {
    padding-top: 0px !important;
    margin-bottom: 0px;
    padding-right: 5px;
    padding-left: 0 !important;
}



//Makes the mat label centered when empty
mat-form-field > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix > .mat-form-field-label-wrapper > .mat-form-field-empty {
    padding-top: 14px !important;
    padding-left: 5px !important;
}

//Makes the mat label float above when focused
.mat-focused > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix > .mat-form-field-label-wrapper > .mat-form-field-label {
    padding-top: 0px !important;
    margin-bottom: 0px;
    padding-right: 5px;
    padding-left: 0 !important;
}

.mat-form-field-label {
    padding-left: 10px !important;
}

//Adjust non floating label for material input text
.mat-empty {
    padding-top: 5px;
}

//Stop floating label from adding the padding-top when empty
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    padding-top: 0 !important;
}

.bl-checkbox-purple > mat-checkbox {
     .mat-checkbox-background {
        background-color: $bl-purple !important;;
    }

    &.mat-checkbox-focused{
        .mat-ink-ripple{
            background-color: red;
        }
    }

  
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: $bl-purple !important;
}

.mat-form-field-wrapper {
    padding: 0 !important;
}

.mat-form-field-infix {
    display: flex !important;
    background-color: #fff !important;
    border-radius: 3px;
    box-shadow: 0px 1px 6px 1px rgb(0 0 0 / 27%);
    width: auto !important;
    border: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
    text-transform: capitalize;
}

.mat-option-disabled > mat-pseudo-checkbox {
    display: none;
  }
  
  .mat-pseudo-checkbox-checked {
    background-color: #D36DDD !important;
  
  }
  
  .mat-selected {
    background-color: rgb(250,243,251) !important;
    color: #D36DDD !important;
  }
  
  .mat-form-field-flex {
    padding: 1px !important;
    align-items: center !important;

  }
  
  .mat-select-value {
    color: #AAAAB2;
    font-weight: bold;
    font-family: "karla", Arial, Helvetica, sans-serif;
  }
  
  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow-wrapper {
   transform: inherit !important;
  } 
  
  .mat-select-arrow {
    border-top: 0px solid !important;
    width: 20px !important;
    height: 10px !important;
    border-radius: none !important;
    background-position: center;
    background-image: url(/assets/img/dropdown-arrow.png);

  }

mat-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

  
::-webkit-scrollbar {
    height: 2px !important;
    width: 2px !important;
}
  
::-webkit-scrollbar-track {
    background: transparent;
    width: 2px;
}

::-webkit-scrollbar-thumb {
    visibility: hidden;
    background: $bl-purple;
    width: 2px;
}
  
:hover::-webkit-scrollbar-thumb {
    @media screen and (min-width: $sm) {
        visibility: inherit;
    }
    width: 2px;
}

label, h1, h2, p {
    color:black;
}

//Prevent clicking mat hint
.mat-form-field-subscript-wrapper  {
    pointer-events: none;
}

.mat-form-field {
    width: 100%;
}

.mat-input-element {
    color: black !important;
}

.mat-radio-inner-circle {
    background: $bl-purple !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $bl-purple;
}

button {
    //background-color: $bl-purple;
}

.mat-slider-track-fill, .mat-slider-thumb {
    background-color: $bl-purple !important;
} 

mwlResizable {
    box-sizing: border-box; // required for the enableGhostResize option to work
}

.resize-handle-bottom {
    position: absolute;
    height: 5px;
    cursor: row-resize;
    width: 100%;
  }
